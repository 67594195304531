<template>
  <div>
    <div class="filter-menu">
      <h1 id="filter-expand">Filter <span id="expand-filter-icon">☰</span></h1>
      <div id="option-container">
        <Filterable-Option
          v-for="(attributeList, property) in allFilters"
          :key="property"
          :property="property"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FilterableOption from "./Filterable-Option.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: { FilterableOption },
  name: "Filter-Menu",
  computed: {
    ...mapGetters(["allFilters"]),
  },
  mounted(){
    document.querySelector('#filter-expand').addEventListener("click", function (){
      let container = document.querySelector('#option-container');
      if (container.style.display == 'block'){
        container.style.display = 'none';
      } else {
        container.style.display = 'block';
      }
    }
    );
  }
});
</script>

<style scoped>
.filter-menu {
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
  background-color: #42b983;
  margin: 10px;
  top: 10px;
  position: sticky;
}
h1 {
  margin: 5px;
  font-size: 15pt;
  cursor: default;
}
#option-container {
  display: none;
}
#expand-filter-icon {
  float: right;
}
@media screen and (min-width: 981px) {
  #option-container {
    display: block !important;
  }
  #expand-filter-icon {
    display: none;
  }
}
</style>