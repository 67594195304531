<template>
  <div class="fiterable-option">
    <h2>{{ property }}</h2>
    <div v-for="(attributeObject, attributeIndex)  in currentProperty" :key="attributeObject.attribute">
        <Filterable-Attribute :property="property" :attributeIndex="attributeIndex"/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import FilterableAttribute from "./Filterable-Attribute.vue";

export default defineComponent({
  components: { FilterableAttribute },
  name: "Filterable-Options",
  props: {
    property: String,
  },
  methods: {},
  computed: {
    ...mapGetters(["allFilters"]),
    currentProperty() {
      return this.allFilters[this.property];
    },
  },
});
</script>

<style scoped>
h2 {
  margin: 5px;
  font-size: 13pt;
}
</style>