<template>
  <div class="browser-view">
    <Filter-Menu/>
    <div>
      <div class="search-confirmation" v-if="searchTerm != ''">Search Results for "{{ searchTerm }}"</div>
    <div class="browser-grid">
          <Product-Card v-for="sequence in filteredSequences" :key="sequence.seqId" :sequence="sequence" />
    </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "../components/Product-Card.vue";
import FilterMenu from '../components/Filter-Menu.vue';
import { mapGetters, mapActions } from "vuex";

export default {
  components: { ProductCard, FilterMenu },
  name: "Browser-View",
  computed: {
    ...mapGetters([
    "filteredSequences", 
    "searchTerm"
    ]),

  },
  methods:{
    ...mapActions([
      'getSequences',
      'getFilters'
      ])
  },
  created() {
    this.getSequences();
    this.getFilters();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
.browser-view {
  display: grid;
  grid-template-columns: 300px auto;
}
.search-confirmation {
  padding-left: 10px;
}
.browser-grid{
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill,minmax(300px, 1fr));
  margin: 10px;
}
@media screen and (max-width:980px){
  .browser-view {
  display: grid;
  grid-template-columns: auto;
  grid-row: auto auto;
}
}
</style>