<template>
  <div class="product-card">
    <router-link :to="'/sequence/' + sequence.seqId">
      <h1>{{ sequence.title }}</h1>
      <i><p>Song by: {{ sequence.artist }}</p>
      <p>Sequenced by: {{ sequence.creator }}</p></i>
    </router-link>
    <Product-Video
      :video-type="sequence.videoType"
      :video-src="sequence.videoSrc"
      :id="sequence.seqId"
    />
    <router-link :to="'/sequence/' + sequence.seqId">
      <a class="button">More Information</a>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProductVideo from "./Product-Video.vue";

export default defineComponent({
  components: { ProductVideo },
  name: "Product-Card",
  props: {
    sequence: Object,
  }
});
</script>

<style scoped>
.video-container {
  aspect-ratio: 16/9;
  height: auto;
}
.product-card {
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: grid;
  grid-template-rows: auto min-content min-content;
}
h1,
.button {
  margin: 5px;
  text-align: center;
}
h1{
  font-size: 25px;
}

.button {
color: white;
  font-family: "Open Sans Condensed", sans-serif;
  padding: 10px;
  background: #2c3e50;
  font-size: 17px;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  margin: 5px;
  display: inline-block;
  
}
.button:hover {
  background: #415568;
}
a {
  text-decoration: none;
  color: inherit;
  text-align: center;
}
</style>