<template>
  <div class="fiterable-attribute">
        <input type="checkbox" :id="active.attribute" v-model="active.value" @input="toggleFilterOnCheck"/>
        <label :for="active.attribute">{{ active.attribute }}</label>
    </div>
    
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";



export default defineComponent({
  name: "Fiterable-Attribute",
  props: {
    property: String,
    attributeIndex: Number
  },
  methods: {
    ...mapActions(['toggleFilter']),
    toggleFilterOnCheck(e){
      e.preventDefault();
      this.toggleFilter({
        property: this.property,
        attribute: this.attributeIndex
      });
    }
  },
 computed: {
    ...mapGetters(["allFilters"]),
    active() {
        return (this.allFilters[this.property][this.attributeIndex]);
    }
  },
});
</script>

<style scoped>
</style>